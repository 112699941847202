import React from 'react';
import { useIntl } from 'react-intl';
import { ContentfulLink } from '../../../graphql-types';
import AppStoreButton from './AppStoreButton';
import GooglePlayStoreButton from './GooglePlayStoreButton';

const APP_STORE_LINKS = {
  de:
    'https://apps.apple.com/de/app/cara-care-for-ibs/id1526811241?_branch_match_id=940486120597422220',
  en: 'https://cara.app.link/vbvd3bev6y',
};

const GOOGLE_PLAY_LINKS = {
  de: 'https://play.google.com/store/apps/details?id=com.gohidoc.caraeu&hl=de',
  en: 'https://cara.app.link/play',
};

interface Props {
  color?: string;
  appStoreLink?: ContentfulLink;
  playStoreLink?: ContentfulLink;
}

export default function AppDownloadButtons({
  color,
  appStoreLink,
  playStoreLink,
  ...rest
}: Props) {
  const intl = useIntl();

  return (
    <div
      css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      {...rest}
    >
      {!(appStoreLink?.isDisplayed === false) && (
        <AppStoreButton
          color={color}
          href={
            appStoreLink?.url ||
            APP_STORE_LINKS[intl.locale as 'en' | 'de'] ||
            APP_STORE_LINKS.en
          }
        />
      )}
      {!(appStoreLink?.isDisplayed === false) && (
        <GooglePlayStoreButton
          color={color}
          href={
            playStoreLink?.url ||
            GOOGLE_PLAY_LINKS[intl.locale as 'en' | 'de'] ||
            GOOGLE_PLAY_LINKS.en
          }
        />
      )}
    </div>
  );
}
