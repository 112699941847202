import React from 'react';
import { css } from '@emotion/core';
import Container from './Container';
import Text from './Text';
import { ButtonLink } from '../common/Button';
import presets from '../../styles/presets';
import background from '../../assets/images/employers-background.svg';
import {
  ContentfulImageLink,
  ContentfulContentBlock,
} from '../../graphql-types';
import { SYSTEM_FONTS } from '../../styles/typography';
import { MonoFontLabel } from '../common/typography';

interface EmployersProps {
  logos: ContentfulImageLink[];
}

export default function Employers({
  prefix,
  title,
  text,
  callToAction,
}: EmployersProps & ContentfulContentBlock) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-image: url(${background});
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        min-height: 793px;
        margin-bottom: 52px;

        ${presets.lg} {
          margin-bottom: 153px;
        }

        @media (min-width: 1930px) {
          min-height: 45vw;
        }
      `}
    >
      <Container
        css={{
          maxWidth: 595 + 24 * 2,
          textAlign: 'center',
        }}
      >
        <MonoFontLabel>{prefix}</MonoFontLabel>
        <h4
          css={{
            fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
            fontSize: 28,
            color: '#fff',
            [presets.md]: {
              fontSize: 40,
            },
          }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Text css={{ color: '#fff', marginBottom: 42 }}>
          {text.childMarkdownRemark.rawMarkdownBody}
        </Text>
        {callToAction && callToAction.isDisplayed && callToAction.url && (
          <ButtonLink to={callToAction.url} css={{ marginBottom: 48 }}>
            {callToAction.text}
          </ButtonLink>
        )}
      </Container>
    </div>
  );
}
